<template>
  <div class="body-box">
    <div class="wrapper" id="canvasImg" ref="print">
      <!-- <el-button type="primary" class="deriveWord noprint" @click="printFn">一键导出</el-button> -->

      <!-- 头部 -->
      <div class="title-top">
        <div class="title-box">
          <div class="photograph">粘贴照片</div>
          <!-- 求职意向区域 -->
          <div class="personal-information">
            <div class="personal-box">
              <div class="name" v-if="obj && obj.name">{{ obj.name }}</div>
              <div class="to-applybox">
                <div class="applyPost" v-if="obj && obj.intention && obj.intention.length">
                  期望职位 {{ obj.intention[0].category_label }}
                </div>
                <div class="applyList">
                  <div v-if="obj && obj.num_work_experience">
                    {{ obj.num_work_experience }}年工作经验
                    <span class="parting-line">|</span>
                  </div>
                  <div
                    v-if="
                      obj &&
                      obj.intention &&
                      obj.intention.length &&
                      obj.intention[0].minwage_name !== '面议'
                    "
                  >
                    期望薪资：{{ obj.intention[0].minwage_name }}-{{
                      obj.intention[0].maxwage_name
                    }}
                    <span class="parting-line">|</span>
                  </div>
                  <div
                    v-if="
                      obj &&
                      obj.intention &&
                      obj.intention.length &&
                      obj.intention[0].minwage_name === '面议'
                    "
                  >
                    期望薪资：{{ obj && obj.intention.length ? obj.intention[0].minwage_name : '' }}
                    <span class="parting-line">|</span>
                  </div>
                  <div v-if="obj && obj.intention && obj.intention.length">
                    工作地区：{{ obj.intention[0].cantonal }}
                    <span class="parting-line">|</span>
                  </div>
                  <div v-if="obj && obj.intention.length">
                    {{ obj.intention[0].nature_name }}
                  </div>
                </div>
              </div>
            </div>
            <!-- 求职意向区域 -->

            <!-- 个人信息区域 -->
            <div class="personal-message">
              <div class="message-box">
                <div v-if="obj && obj.gender">性别：{{ obj.gender == 1 ? '男' : '女' }}</div>
                <div v-if="obj && obj.date_of_birth">出生年月：{{ obj.date_of_birth }}</div>
                <div v-if="obj && obj.wechat">微信号：{{ obj.wechat }}</div>
                <div v-if="obj && obj.age">年龄：{{ obj.age }}</div>
                <div v-if="obj && obj.phone_number">手机号：{{ obj.phone_number }}</div>
                <div v-if="obj && obj.email">邮箱：{{ obj.email }}</div>
              </div>
            </div>
            <!-- 个人信息区域 -->
          </div>
        </div>
      </div>
      <!-- 头部 -->

      <!-- 教育经历 -->
      <div class="Box" v-if="obj && obj.education_experience && obj.education_experience.length">
        <div class="Box-list">
          <div class="list-left">
            <img src="@/assets//image//pdfExport/icon (1).png" alt="" />
            <div class="left-text">教育经历</div>
          </div>
          <div class="list-rigth">
            <div v-for="(item, index) in obj.education_experience" :key="index">
              <div class="rigth-title">{{ item.school_name }}</div>
              <div class="rigth-cenetn jl">
                <div>{{ item.major }}</div>
                <div>{{ item.degree }}</div>
                <div>{{ item.start_time_startDate }}-{{ item.start_time_endDate }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 教育经历 -->

      <!-- 工作经历 -->
      <div class="Box" v-if="obj && obj.work_experience && obj.work_experience.length">
        <div class="Box-list">
          <div class="list-left">
            <img src="@/assets//image//pdfExport//icon.png" alt="" />
            <div class="left-text">工作经历</div>
          </div>
          <div class="list-rigth">
            <div v-for="(item, index) in obj.work_experience" :key="index">
              <div class="rigth-title question-table">{{ item.company_name }}</div>
              <div class="rigth-cenetn question-table">
                <div>{{ item.job_title }}</div>
                <div>{{ item.start_time_startDate }}-{{ item.start_time_endDate }}</div>
              </div>
              <div class="rigth-list">
                <div
                  class="listText"
                  v-for="(text, index) in item.work_experienceList"
                  :key="index"
                >
                  <img src="@/assets//image//pdfExport/装饰.png" alt="" />
                  <div>{{ text }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 工作经历 -->

      <!-- 项目经历 -->
      <div class="Box" v-if="obj && obj.project_experience && obj.project_experience.length">
        <div class="Box-list question-table">
          <div class="list-left">
            <img src="@/assets//image//pdfExport/icon .png" alt="" />
            <div class="left-text">项目经历</div>
          </div>
          <div class="list-rigth">
            <div v-for="(item, index) in obj.project_experience" :key="index">
              <div class="rigth-title">{{ item.project_name }}</div>
              <div class="rigth-cenetn">
                <div>{{ item.job_title }}</div>
                <div>{{ item.start_time_startDate }}-{{ item.start_time_endDate }}</div>
              </div>
              <div class="rigth-list">
                <div class="list-describe">经历描述：</div>
                <div class="describeText">
                  {{ item.description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 项目经历 -->

      <!-- 商业技能 -->
      <div class="Box" v-if="obj && obj.skills_full && obj.skills_full.length">
        <div class="Box-list question-table">
          <div class="list-left">
            <img src="@/assets//image//pdfExport/icon(1).png" alt="" />
            <div class="left-text">相关技能</div>
          </div>
          <div class="list-rigth">
            <div v-for="(item, index) in obj.skills_full" :key="index">
              <div class="rigth-title">{{ item.name }}</div>
              <div class="rigth-cenetn">
                <div>掌握时长： {{ item.time }}个月</div>
                <div class="level">{{ item.level }}</div>
              </div>
              <div class="rigth-list">
                <div class="list-describe">技能描述：</div>
                <div class="describeText">{{ item.description }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 商业技能 -->

      <!-- 荣誉奖项 -->
      <div class="Box" v-if="obj && obj.awards_full && obj.awards_full.length">
        <div class="Box-list question-table">
          <div class="list-left">
            <img src="@/assets//image//pdfExport/icon (2).png" alt="" />
            <div class="left-text">荣誉奖项</div>
          </div>
          <div class="list-rigth">
            <div class="rigth-honor" v-for="(item, index) in obj.awards_full" :key="index">
              <div class="honor-left">
                <div>{{ item.name }}</div>
              </div>
              <div class="honor-rigth">
                <div>获得时间：{{ item.time }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 荣誉奖项 -->

      <!-- 自我评价 -->
      <div class="Box" v-if="obj && obj.self_evaluation">
        <div class="Box-list question-table">
          <div class="list-left">
            <img src="@/assets//image//pdfExport/icon (3).png" alt="" />
            <div class="left-text">自我评价</div>
          </div>
          <div class="list-rigth">
            <div class="gigth-evaluate">
              {{ obj.self_evaluation }}
            </div>
          </div>
        </div>
      </div>
      <!-- 自我评价 -->
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PdfLoader from '@/utils/htmlpdf.js'
import { scImg } from '@/utils/scImg'
import { upyunUrl } from '@/api/config'
export default {
  data() {
    return {
      name: '',
      obj: null,
      upyunData: { policy: '', signature: '' }
    }
  },
  computed: {
    ...mapState(['currentResumeInfo'])
  },
  methods: {
    ...mapActions(['resumeFileUpyunPdf', 'getUpYunSign']),
    async printFn() {
      await this.getUpYunSignFn()
      this.name = this.obj.resume_name
      if (this.name === null) {
        //拼接导出文件名字
        name = `${this.obj.name || 'xxx'}-${
          this.obj.intention.length ? this.obj.intention[0].intentionName : '岗位'
        }-${this.obj.num_work_experience || ''}年/${
          this.obj.education_experience.length ? this.obj.education_experience[0].end_time_year : ''
        }届毕业`
      }
      // son防止文字截断的class名  此方法作用是转成base64
      const pdf = new PdfLoader(document.getElementById('canvasImg'), this.name, 'question-table')
      pdf.outPutPdfFn('customFileName').then(base64PDF => {
        // 处理 base64PDF 数据
        this.UploadPdf(base64PDF)
      })

      //  直接pdf导出的方法 需要把htmlpdf.js的注释方法也要打开
      //  const pdf = document.getElementById('canvasImg') // 需要导出部分页面的id名
      //  this.pdfDownLoader = new PdfLoader(pdf, this.name, 'question-table') // fileName -->导出文件名,  question-table -->防止被截断的class名
      //  this.pdfDownLoader.outPutPdfFn(this.name)
    },
    // 上传pdf接口
    async UploadPdf(res) {
      // res拿到base64的pdf
      let pdfBase64Str = res
      let title = this.name
      // 把简历名称存起来方便resumeFileUpyunFn接口取值
      sessionStorage.setItem('resume_title', title)
      let file = await this.dataURLtoFile(pdfBase64Str, title + '.pdf') // 调用一下下面的转文件流函数
      await this.resumeFileUpyunFn(file)
    },
    // 将base64转换为文件,接收2个参数，第一是base64，第二个是文件名字最后返回文件对象
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    // 上传简历
    async resumeFileUpyunFn(file) {
      const data = new FormData()
      data.append('file', file)
      data.append('policy', this.upyunData.policy)
      data.append('signature', this.upyunData.signature)
      const obj = await this.resumeFileUpyunPdf(data)
      const oJson = {
        id: this.currentResumeInfo.id,
        resume_url: upyunUrl + obj.url,
        resume_name: this.resumeName,
        file_size: obj.file_size,
        all_result_n: null
      }
      // oJson就是简历的详情和在线链接  方便父组件调用
      this.$emit('dialogVisibleFn', oJson)
    },
    // 打印
    handlePrint() {
      this.$print(this.$refs.print)
    },
    // 获取又拍云签名
    async getUpYunSignFn() {
      this.upyunData = await this.getUpYunSign()
    },
    // 页面转图方法
    async scImg() {
      const res = await scImg()
      return res
    }
  },
  created() {
    this.obj = this.currentResumeInfo
  },
  watch: {
    currentResumeInfo(obj) {
      this.obj = obj
    }
  }
}
</script>

<style scoped lang="stylus">
.body-box
  background #f9f9f9
  border-radius 10px
.wrapper
  max-width 828px
  background #fff
  padding 21px 40px 10px 33px
  box-sizing border-box
  border-radius 10px
  .title-top
    .title-box
      display flex
      justify-content space-between
      border-bottom 2px solid #48A9E1
      padding-bottom 25px
      box-sizing border-box
      .photograph
        width 117px
        height 150px
        background #F1FAFF
        border 1px solid #48A9E1
        font-size 12px
        font-family PingFang SC
        font-weight 400
        color #A3A6B5
        line-height 150px
        text-align center
        margin-right 37px
      .personal-information
        flex 1
        .personal-box
          display flex
          align-items center
          .name
            font-size 28px
            font-family PingFang SC
            font-weight bold
            color #333333
            margin-right 10px
          .to-applybox
            .applyPost
              display flex
              align-items center
              justify-content center
              width fit-content
              height auto
              font-size 13px
              font-weight 500
              color #2f9bda
            .applyList
              display flex
              height 12px
              font-size 13px
              font-family PingFang SC
              font-weight 500
              color #2F9BDA
              .parting-line
                margin 0 4px
                color #999
                font-size 1px
        .personal-message
          margin-top 28px
          .message-box
            display flex
            font-size 13px
            font-family PingFang SC
            font-weight 500
            color #333333
            line-height 31px
            flex-wrap wrap
            div
              width 50%
            .message-left
              margin-right 140px
  .Box
    padding 0px 0px 0px 25px
    border-bottom 2px solid #48A9E1
    box-sizing border-box
    .Box-list
      display flex
      .list-left
        display flex
        align-items center
        margin-right 35px
        img
          width 25px
          height 25px
          margin-right 4px
        .left-text
          font-size 19px
          font-family PingFang SC
          font-weight bold
          color #333333
    .list-rigth
      flex 1
      margin-top 25px
      .rigth-title
        font-size 15px
        font-family PingFang SC
        font-weight bold
        color #2F9BDA
      .rigth-honor
        display flex
        justify-content space-between
        .honor-left
          font-size 15px
          font-family PingFang SC
          font-weight bold
          color #2F9BDA
          div
            margin-bottom 25px
        .honor-rigth
          font-size 15px
          font-family PingFang SC
          font-weight bold
          color #333333
          margin-right 100px
          div
            margin-bottom 25px
      .rigth-cenetn
        margin-top 14px
        display flex
        justify-content space-between
        font-size 15px
        font-family PingFang SC
        font-weight bold
        color #333333
        .level
          margin-right 210px
      .rigth-cenetn.jl
        margin-bottom 25px
      .rigth-list
        margin-top 14px
        margin-bottom 26px
        .listText
          display flex
          line-height 30px
          img
            width 6px
            height 6px
            margin-right 4px
            margin-top 14px
        .list-describe
          font-size 13px
          font-family PingFang SC
          font-weight bold
          color #333333
        .describeText
          margin-top 12px
          font-size 13px
          font-family PingFang SC
          font-weight 500
          color #333333
          line-height 27px
      .gigth-evaluate
        font-size 13px
        font-family PingFang SC
        font-weight 500
        color #333333
        line-height 27px
        margin-bottom 25px
  .deriveWord
    position fixed
    top 50%
    right 0
</style>
