<template>
  <div class="body-box">
    <div class="wrapper" id="canvasImg" ref="print">
      <!-- <el-button type="primary" class="deriveWord noprint" @click="printFn">一键导出</el-button> -->
      <div class="Title">
        <img src="@/assets//image//pdfExport/组 1 (1).png" alt="" />
      </div>
      <div class="kill-box">
        <div class="top-killBox">
          <div class="name-and">
            <div class="name" v-if="obj && obj.name">{{ obj.name }}</div>
            <div class="age-box">
              <div class="gender" v-if="obj && obj.gender">
                {{ obj.gender == 1 ? '男' : '女' }}
              </div>
              <div class="parting-line" v-if="obj && obj.gender">|</div>
              <div class="age" v-if="obj && obj.age">{{ obj.age }}</div>
            </div>
          </div>
          <!-- 求职意向区域 -->
          <div class="job-intention">
            <div class="work" v-if="obj && obj.num_work_experience">
              {{ obj.num_work_experience }}年工作经验
              <span class="parting-line jl">|</span>
            </div>

            <div class="position" v-if="obj && obj.intention && obj.intention.length">
              期望职位： {{ obj.intention[0].category_label }}
              <span class="parting-line jl">|</span>
            </div>
            <div
              class="pay"
              v-if="
                obj &&
                obj.intention &&
                obj.intention.length &&
                obj.intention[0].minwage_name !== '面议'
              "
            >
              期望薪资：{{ obj.intention[0].minwage_name }}-{{ obj.intention[0].maxwage_name }}
              <span class="parting-line jl">|</span>
            </div>
            <div
              class="pay"
              v-if="
                obj &&
                obj.intention &&
                obj.intention.length &&
                obj.intention[0].minwage_name === '面议'
              "
            >
              期望薪资：{{ obj && obj.intention.length ? obj.intention[0].minwage_name : '' }}
              <span class="parting-line jl">|</span>
            </div>
            <div class="region" v-if="obj && obj.intention && obj.intention.length">
              工作地区： {{ obj.intention[0].cantonal }}
              <span class="parting-line jl">|</span>
            </div>
            <div class="state" v-if="obj && obj.intention.length">
              {{ obj.intention[0].nature_name }}
            </div>
          </div>
          <!-- 求职意向区域 -->

          <!-- 个人信息区域 -->
          <div class="personal">
            <!-- <div class="personal-left"> -->
            <div v-if="obj && obj.date_of_birth">出生年月: {{ obj.date_of_birth }}</div>
            <div v-if="obj && obj.wechat">微信号: {{ obj.wechat }}</div>
            <div v-if="obj && obj.home_phone_number">固定电话：{{ obj.home_phone_number }}</div>
            <div v-if="obj && obj.QQ">QQ: {{ obj.QQ }}</div>
            <div v-if="obj && obj.height">身高: {{ obj.height }}</div>
            <div v-if="obj && obj.ethnic">民族：{{ obj.ethnic }}</div>
            <div v-if="obj && obj.current_location">现居城市：{{ obj.current_location }}</div>
            <div v-if="obj && obj.zipcode">邮编：{{ obj.zipcode }}</div>
            <div v-if="obj && obj.phone_number">手机号: {{ obj.phone_number }}</div>
            <div v-if="obj && obj.email">邮箱：{{ obj.email }}</div>
            <div v-if="obj && obj.identity">
              身份：{{ obj.identity == 1 ? '在校生' : obj.identity == 2 ? '应届生' : '社会求职' }}
            </div>
            <div v-if="obj && obj.weight">体重: {{ obj.weight }}kg</div>
            <div v-if="obj && obj.birthplace">籍贯：{{ obj.birthplace }}</div>
            <div v-if="obj && obj.detailed_location">详细地址：{{ obj.detailed_location }}</div>
            <div v-if="obj && obj.marital_status && obj.marital_status >= 0">
              婚姻状况 ：{{
                obj.marital_status == 0
                  ? '未婚'
                  : obj.marital_status == 1
                  ? '已婚'
                  : obj.marital_status == 3
                  ? '离异'
                  : '再婚'
              }}
            </div>
            <div v-if="obj && obj.marital_status && obj.marital_status.length > 1">
              婚姻状况 ：{{ obj.marital_status }}
            </div>
            <!-- </div> -->

            <!-- <div class="personal-rigth"></div> -->
          </div>
          <!-- 个人信息区域 -->
        </div>
        <!-- 粘贴照片区域 -->
        <div class="picture">粘贴照片</div>
        <!-- 粘贴照片区域 -->
      </div>

      <!-- 工作经历区域 -->
      <div class="work-experience" v-if="obj && obj.work_experience && obj.work_experience.length">
        <!-- 标题框部分-->
        <div class="title-box">
          <img src="@/assets//image//pdfExport//组 1.png" alt="" />
        </div>
        <!-- 标题框部分-->

        <div
          class="work-content question-table"
          v-for="(item, index) in obj.work_experience"
          :key="index"
        >
          <div class="work-title">
            <div style="width: 272px">{{ item.company_name }}</div>
            <div style="width: 213px">{{ item.job_title }}</div>
            <div>{{ item.start_time_startDate }}-{{ item.start_time_endDate }}</div>
          </div>

          <div class="work-gothrough">
            <div class="undergo" v-for="(text, index) in item.work_experienceList" :key="index">
              <i class="iconfont icon-paixujiantou"></i>
              <div class="wrap">
                {{ text }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 工作经历区域 -->

      <!-- 项目经历区域 -->
      <div
        class="Project-experience"
        v-if="obj && obj.project_experience && obj.project_experience.length"
      >
        <!-- 标题框部分-->
        <div class="title-box">
          <img src="@/assets//image//pdfExport//组 2.png" alt="" />
        </div>
        <!-- 标题框部分-->

        <div
          class="work-content question-table"
          v-for="(item, index) in obj.project_experience"
          :key="index"
        >
          <div class="work-title">
            <div style="width: 272px">{{ item.project_name }}</div>
            <div style="width: 213px">{{ item.job_title }}</div>
            <div>{{ item.start_time_startDate }}-{{ item.start_time_endDate }}</div>
          </div>
          <div class="work-gothrough">
            <div class="describe-text">经历描述：</div>
            <div class="Project-desribe">
              {{ item.description }}
            </div>
          </div>
        </div>
      </div>
      <!-- 项目经历区域 -->

      <!-- 教育经历 -->
      <div
        class="Project-experience"
        v-if="obj && obj.education_experience && obj.education_experience.length"
      >
        <!-- 标题框部分-->
        <div class="title-box">
          <img src="@/assets//image//pdfExport//组 3.png" alt="" />
        </div>
        <!-- 标题框部分-->

        <div class="work-content education question-table">
          <div
            class="work-title textlist"
            v-for="(item, index) in obj.education_experience"
            :key="index"
          >
            <div style="width: 191px">{{ item.school_name }}</div>
            <div style="width: 212px">{{ item.major }}</div>
            <div style="width: 89px">{{ item.degree }}</div>
            <div>{{ item.start_time_startDate }}-{{ item.start_time_endDate }}</div>
          </div>
        </div>
      </div>
      <!-- 教育经历 -->

      <!-- 技能区域 -->
      <div
        class="Project-experience question-table"
        v-if="obj && obj.skills_full && obj.skills_full.length"
      >
        <!-- 标题框部分-->
        <div class="title-box">
          <img src="@/assets//image//pdfExport//组 4.png" alt="" />
        </div>
        <!-- 标题框部分-->

        <div class="work-content" v-for="(item, index) in obj.skills_full" :key="index">
          <div class="work-title">
            <div style="width: 203px">{{ item.name }}</div>
            <div style="width: 199px">掌握时长： {{ item.time }}个月</div>
            <el-progress
              :percentage="item.percentage"
              :format="format"
              :color="customColor"
            ></el-progress>
          </div>

          <div class="work-gothrough">
            <div class="describe-text">技能描述：</div>
            <div class="Project-desribe">{{ item.description }}</div>
          </div>
        </div>
      </div>
      <!-- 技能区域 -->

      <!-- 荣誉奖项区域 -->
      <div class="Project-experience" v-if="obj && obj.awards_full && obj.awards_full.length">
        <!-- 标题框部分-->
        <div class="title-box">
          <img src="@/assets//image//pdfExport//组 5.png" alt="" />
        </div>
        <!-- 标题框部分-->

        <div
          class="work-content question-table"
          v-for="(item, index) in obj.awards_full"
          :key="index"
        >
          <div class="awards-box">
            <div class="awards-left">
              <div class="awardsText">{{ item.name }}</div>
            </div>
            <div class="awards-rigth">
              <div class="awardsText">获得时间：{{ item.time }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 荣誉奖项区域 -->

      <!-- 自我评价区域 -->
      <div class="Project-experience self question-table" v-if="obj && obj.self_evaluation">
        <!-- 标题框部分-->
        <div class="title-box">
          <img src="@/assets//image//pdfExport//组 6.png" alt="" />
        </div>
        <!-- 标题框部分-->

        <div class="self-assessment question-table">
          {{ obj.self_evaluation }}
        </div>
      </div>
      <!-- 自我评价区域 -->
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PdfLoader from '@/utils/htmlpdf.js'
import { scImg } from '@/utils/scImg'
import { upyunUrl } from '@/api/config'
export default {
  data() {
    return {
      customColor: [{ color: '#4666E0' }],
      upyunData: { policy: '', signature: '' },
      obj: null,
      name: ''
    }
  },
  computed: {
    ...mapState(['currentResumeInfo'])
  },
  created() {
    this.obj = this.currentResumeInfo
    this.skillsFullFn()
  },
  methods: {
    ...mapActions(['resumeFileUpyunPdf', 'getUpYunSign']),
    //获取名字
    getName() {
      this.name = this.obj.resume_name
      if (this.name === null) {
        //拼接导出文件名字
        this.name = `${this.obj.name || 'xxx'}-${
          this.obj.intention.length ? this.obj.intention[0].intentionName : '岗位'
        }-${this.obj.num_work_experience || ''}年/${
          this.obj.education_experience.length ? this.obj.education_experience[0].end_time_year : ''
        }届毕业`
      }
    },
    async scImg() {
      const res = await scImg()
      return res
    },
    skillsFullFn() {
      if (this.obj.skills_full.length) {
        this.obj.skills_full.forEach(item => {
          if (item.level === '一般') {
            item.percentage = 50
          } else if (item.level === '良好') {
            item.percentage = 70
          } else {
            item.percentage = 100
          }
        })
      }
    },
    format(percentage) {
      return percentage === 50 ? '一般' : percentage === 70 ? '良好' : '精通'
    },
    async printFn() {
      await this.getName()
      await this.getUpYunSignFn()
      // son防止文字截断的class名  此方法作用是转成base64
      const pdf = new PdfLoader(document.getElementById('canvasImg'), this.name, 'question-table')
      pdf.outPutPdfFn('customFileName').then(base64PDF => {
        // 处理 base64PDF 数据
        this.UploadPdf(base64PDF)
      })

      // 直接pdf导出的方法 需要把htmlpdf.js的注释方法也要打开
      //  const pdf = document.getElementById('canvasImg') // 需要导出部分页面的id名
      // this.pdfDownLoader = new PdfLoader(pdf, this.name, 'question-table') // fileName -->导出文件名,  question-table -->防止被截断的class名
      // this.pdfDownLoader.outPutPdfFn(this.name)
    },
    // 上传pdf接口
    async UploadPdf(res) {
      // res拿到base64的pdf
      let pdfBase64Str = res
      let title = this.name
      // 把简历名称存起来方便resumeFileUpyunFn接口取值
      sessionStorage.setItem('resume_title', title)
      let file = await this.dataURLtoFile(pdfBase64Str, title + '.pdf') // 调用一下下面的转文件流函数
      await this.resumeFileUpyunFn(file)
    },
    // 将base64转换为文件,接收2个参数，第一是base64，第二个是文件名字最后返回文件对象
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    // 上传简历
    async resumeFileUpyunFn(file) {
      const data = new FormData()
      data.append('file', file)
      data.append('policy', this.upyunData.policy)
      data.append('signature', this.upyunData.signature)
      const obj = await this.resumeFileUpyunPdf(data)
      const oJson = {
        id: this.currentResumeInfo.id,
        resume_url: upyunUrl + obj.url,
        resume_name: this.resumeName,
        file_size: obj.file_size,
        all_result_n: null
      }
      // oJson就是简历的详情和在线链接  方便父组件调用
      this.$emit('dialogVisibleFn', oJson)
    },
    // 打印
    handlePrint() {
      this.$print(this.$refs.print)
    },
    // 获取又拍云签名
    async getUpYunSignFn() {
      this.upyunData = await this.getUpYunSign()
    }
  },
  watch: {
    currentResumeInfo(obj) {
      this.obj = obj
      this.skillsFullFn()
    }
  }
}
</script>

<style scoped lang="stylus">
.body-box
  background #f9f9f9
  border-radius 10px
.wrapper
  max-width 828px
  background #fff
  padding 21px 40px 10px 33px
  box-sizing border-box
  border-radius 10px
  .kill-box
    display flex
    justify-content space-between
  .parting-line
    height 15px
    margin-bottom 1px
    color #999
    font-size 1px
  .parting-line.jl
    margin 0 4px
  .Title
    width 130px
    height 70px
    img
      width 130px
      height 70px
  .name-and
    display flex
    align-items center
    margin-top 9px
    position relative
    .name
      font-size 28px
      color #333333
      margin-right 7px
    .age-box
      display flex
      margin-top 11px
      height 11px
      align-items center
      .gender
        font-size 12px
        color #333333
        margin-right 9px
      .age
        font-size 12px
        color #333333
        padding-left 9px
  .job-intention
    margin-top 16px
    height 11px
    font-size 12px
    font-weight 500
    color #4666E0
    display flex
    align-items center
    .work
      line-height 11px
    .position
      line-height 11px
    .pay
      line-height 11px
    .region
      line-height 11px
    .state
      margin-bottom 1px
.personal
  margin-top 15px
  display flex
  justify-content space-between
  font-size 12px
  color #333333
  width 520px
  line-height 26px
  flex-wrap wrap
  div
    width 50%
.picture
  width 117px
  max-width 117px
  max-height 150px
  height 150px
  line-height 150px
  background #F2F8FD
  border 1px solid #4666E0
  text-align center
  font-size 12px
  color #A3A6B5
.work-experience
  margin-top 49px
  border-top 1px solid #4666E0
  border-left 1px solid #4666E0
  border-bottom 1px solid #4666E0
  position relative
  padding 15px 0 19px 11px
  box-sizing border-box
  .title-box
    position absolute
    left -12px
    top -24px
    width 112px
    height 30px
  .work-content
    .work-title
      display flex
      justify-content space-between
      font-size 14px
      font-weight bold
      color #333333
      font-family PingFang SC
    .work-gothrough
      margin-top 15px
      line-height 26px
      margin-bottom 29px
      .undergo
        display flex
        font-size 12px
        font-family PingFang SC
        font-weight 500
        color #333
.Project-experience
  border-top 0px solid #4666E0
  border-left 1px solid #4666E0
  border-bottom 1px solid #4666E0
  position relative
  padding 15px 0 19px 11px
  box-sizing border-box
  .title-box
    position absolute
    left -12px
    top -24px
    width 112px
    height 30px
  .work-content
    .work-title
      display flex
      font-size 14px
      color #333333
      font-weight bold
      font-family PingFang SC
    .awards-box
      display flex
      .awardsText
        margin-bottom 30px
      .awards-left
        width 203px
    .work-gothrough
      margin-top 15px
      margin-bottom 29px
      .describe-text
        font-size 12px
        font-weight bold
        color #333333
        font-family PingFang SC
      .Project-desribe
        font-family PingFang SC
        font-weight 500
        margin-top 11px
        font-size 12px
        color #333333
        line-height 26px
        word-wrap break-word
  .work-content.education
    margin-bottom 19px
    .work-title.textlist
      margin-bottom 29px
.Project-experience.self
  border-bottom 0px
/deep/ .el-progress.el-progress--line
  height 10px
  width 40%
/deep/ .el-progress__text
  margin-left 15px !important
  font-size 18px !important
  color #333333 !important
.deriveWord
  position fixed
  top 50%
  right 0
</style>
