<template>
  <div class="body-box">
    <div class="wrapper" id="canvasImg" ref="print">
      <div class="Box">
        <div class="Box-left">
          <div class="photograph">粘贴照片</div>
          <div class="name" v-if="obj && obj.name">{{ obj.name }}</div>
          <!-- 期望职位区域 -->
          <div class="position-box question-table">
            <div class="expectation" v-if="obj && obj.intention && obj.intention.length">
              期望职位: {{ obj.intention[0].category_label }}
            </div>
            <div class="years" v-if="obj && obj.num_work_experience">
              <span class="yuan"></span>
              <span>工作年限：{{ obj.num_work_experience }}年</span>
            </div>
            <div
              class="years"
              v-if="
                obj &&
                obj.intention &&
                obj.intention.length &&
                obj.intention[0].minwage_name !== '面议'
              "
            >
              <span class="yuan"></span>
              <span>
                期望薪资：{{ obj.intention[0].minwage_name }}-{{ obj.intention[0].maxwage_name }}
              </span>
            </div>
            <div
              class="years"
              v-if="
                obj &&
                obj.intention &&
                obj.intention.length &&
                obj.intention[0].minwage_name === '面议'
              "
            >
              <span class="yuan"></span>
              <span>
                期望薪资：{{ obj && obj.intention.length ? obj.intention[0].minwage_name : '' }}
              </span>
            </div>
            <div class="years" v-if="obj && obj.intention && obj.intention.length">
              <span class="yuan"></span>
              <span>工作地区：{{ obj.intention[0].cantonal }}</span>
            </div>
            <div class="years" v-if="obj && obj.intention && obj.intention.length">
              <span class="yuan"></span>
              <span>工作性质：{{ obj.intention[0].nature_name }}</span>
            </div>
          </div>
          <!-- 期望职位区域 -->

          <!-- 个人信息区域 -->
          <div class="information-box question-table">
            <div class="information-img">
              <img src="@/assets/image/pdfExport/five/组 1.png" alt="" />
            </div>
            <div class="box-list">
              <div v-if="obj && obj.gender">性别：{{ obj.gender == 1 ? '男' : '女' }}</div>
              <div v-if="obj && obj.age">年龄：{{ obj.age }}</div>
              <div v-if="obj && obj.date_of_birth">出生年月：{{ obj.date_of_birth }}</div>
              <div v-if="obj && obj.phone_number">手机号：{{ obj.phone_number }}</div>
              <div v-if="obj && obj.email">邮箱：{{ obj.email }}</div>
            </div>
          </div>
          <!-- 个人信息区域 -->

          <!-- 相关技能区域 -->
          <div
            class="skill-box question-table"
            v-if="obj && obj.skills_full && obj.skills_full.length"
          >
            <div class="skill-img">
              <img src="@/assets/image/pdfExport/five/组 2.png" alt="" />
            </div>
            <div class="skill-list" v-for="(item, index) in obj.skills_full" :key="index">
              <div class="skill-title">
                <div class="skill-text">{{ item.name }}</div>
                <div class="level">{{ item.level }}</div>
              </div>
              <div class="skill-time">掌握时长：{{ item.time }}个月</div>
              <div class="describe">技能描述：</div>
              <div class="describe-text">{{ item.description }}</div>
            </div>
          </div>
          <!-- 相关技能区域 -->

          <!--荣誉奖项区域-->
          <div
            class="honor-box question-table"
            v-if="obj && obj.awards_full && obj.awards_full.length"
          >
            <div class="honor-img">
              <img src="@/assets/image/pdfExport/five/组 3.png" alt="" />
            </div>
            <div class="honor-list" v-for="(item, index) in obj.awards_full" :key="index">
              <div class="certificate">{{ item.name }}</div>
              <div class="honor-time">获得时间：{{ item.time }}</div>
            </div>
          </div>
          <!--荣誉奖项区域-->
        </div>
        <div class="Box-rigth">
          <!-- 工作经历 -->
          <div
            class="experience-box question-table"
            v-if="obj && obj.work_experience && obj.work_experience.length"
          >
            <div class="experience-title">
              <div class="title-img">
                <img src="@/assets/image/pdfExport/five/icon.png" alt="" />
              </div>
              <div class="title-text">工作经历</div>
              <div class="title-solid"></div>
            </div>
            <!-- 详细信息区域 -->
            <div class="detailed-box" v-for="(item, index) in obj.work_experience" :key="index">
              <div class="company">{{ item.company_name }}</div>
              <div class="letter">
                <div>{{ item.job_title }}</div>
                <div>{{ item.start_time_startDate }}-{{ item.start_time_endDate }}</div>
              </div>
              <div class="describe-box">
                <div
                  class="describe-list"
                  v-for="(text, index) in item.work_experienceList"
                  :key="index"
                >
                  <div class="describe-text">{{ text }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 工作经历 -->

          <!-- 项目经历 -->
          <div
            class="experience-box question-table"
            v-if="obj && obj.project_experience && obj.project_experience.length"
          >
            <div class="experience-title question-table">
              <div class="title-img">
                <img src="@/assets/image/pdfExport/five/icon .png" alt="" />
              </div>
              <div class="title-text">项目经历</div>
              <div class="title-solid"></div>
            </div>
            <!-- 详细信息区域 -->
            <div
              class="detailed-box question-table"
              v-for="(item, index) in obj.project_experience"
              :key="index"
            >
              <div class="company question-table">{{ item.project_name }}</div>
              <div class="letter question-table">
                <div>{{ item.job_title }}</div>
                <div>{{ item.start_time_startDate }}-{{ item.start_time_endDate }}</div>
              </div>
              <div class="describe-box question-table">
                <div class="describe-text project question-table">
                  {{ item.description }}
                </div>
              </div>
            </div>
          </div>
          <!-- 项目经历 -->

          <!-- 教育经历 -->
          <div
            class="experience-box question-table"
            v-if="obj && obj.education_experience && obj.education_experience.length"
          >
            <div class="experience-title">
              <div class="title-img">
                <img src="@/assets/image/pdfExport/five/icon (1).png" alt="" />
              </div>
              <div class="title-text">教育经历</div>
              <div class="title-solid"></div>
            </div>
            <!-- 详细信息区域 -->
            <div
              class="detailed-box"
              v-for="(item, index) in obj.education_experience"
              :key="index"
            >
              <div class="letter company">
                <div class="school">{{ item.school_name }}</div>
                <div>{{ item.degree }}</div>
              </div>
            </div>
          </div>
          <!-- 教育经历 -->

          <!-- 自我评价 -->
          <div class="experience-box question-table" v-if="obj && obj.self_evaluation">
            <div class="experience-title">
              <div class="title-img">
                <img src="@/assets/image/pdfExport/five/icon (2).png" alt="" />
              </div>
              <div class="title-text">自我评价</div>
              <div class="title-solid"></div>
            </div>
            <!-- 详细信息区域 -->
            <!-- 详细信息区域 -->
            <!-- 详细信息区域 -->
            <div class="detailed-box">
              <div class="describe-box">
                <div class="describe-text project">
                  {{ obj.self_evaluation }}
                </div>
              </div>
            </div>
          </div>
          <!-- 自我评价 -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PdfLoader from '@/utils/htmlpdf.js'
import { scImg } from '@/utils/scImg'
import { upyunUrl } from '@/api/config'
export default {
  data() {
    return {
      upyunData: { policy: '', signature: '' },
      obj: null,
      name: ''
    }
  },
  computed: {
    ...mapState(['currentResumeInfo'])
  },
  created() {
    this.obj = this.currentResumeInfo
  },
  methods: {
    ...mapActions(['resumeFileUpyunPdf', 'getUpYunSign']),
    async printFn() {
      await this.getUpYunSignFn()
      this.name = this.obj.resume_name
      if (this.name === null) {
        //拼接导出文件名字
        this.name = `${this.obj.name || 'xxx'}-${
          this.obj.intention.length ? this.obj.intention[0].intentionName : '岗位'
        }-${this.obj.num_work_experience || ''}年/${
          this.obj.education_experience.length ? this.obj.education_experience[0].end_time_year : ''
        }届毕业`
      }
      // son防止文字截断的class名  此方法作用是转成base64
      const pdf = new PdfLoader(document.getElementById('canvasImg'), this.name, 'question-table')
      pdf.outPutPdfFn('customFileName').then(base64PDF => {
        // 处理 base64PDF 数据
        this.UploadPdf(base64PDF)
      })
      // 直接pdf导出的方法 需要把htmlpdf.js的注释方法也要打开
      // const pdf = document.getElementById('canvasImg') // 需要导出部分页面的id名
      // this.pdfDownLoader = new PdfLoader(pdf, name, 'question-table') // fileName -->导出文件名,  question-table -->防止被截断的class名
      // this.pdfDownLoader.outPutPdfFn(name)
    },
    // 上传pdf接口
    async UploadPdf(res) {
      // res拿到base64的pdf
      let pdfBase64Str = res
      let title = this.name
      // 把简历名称存起来方便resumeFileUpyunFn接口取值
      sessionStorage.setItem('resume_title', title)
      let file = await this.dataURLtoFile(pdfBase64Str, title + '.pdf') // 调用一下下面的转文件流函数
      await this.resumeFileUpyunFn(file)
    },
    // 将base64转换为文件,接收2个参数，第一是base64，第二个是文件名字最后返回文件对象
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    // 上传简历
    async resumeFileUpyunFn(file) {
      const data = new FormData()
      data.append('file', file)
      data.append('policy', this.upyunData.policy)
      data.append('signature', this.upyunData.signature)
      const obj = await this.resumeFileUpyunPdf(data)
      const oJson = {
        id: this.currentResumeInfo.id,
        resume_url: upyunUrl + obj.url,
        resume_name: this.resumeName,
        file_size: obj.file_size,
        all_result_n: null
      }
      // oJson就是简历的详情和在线链接  方便父组件调用
      this.$emit('dialogVisibleFn', oJson)
    },
    // 页面转图方法
    async scImg() {
      const res = await scImg()
      return res
    },
    // 打印
    handlePrint() {
      this.$print(this.$refs.print)
    },
    // 获取又拍云签名
    async getUpYunSignFn() {
      this.upyunData = await this.getUpYunSign()
    }
  },
  watch: {
    currentResumeInfo(obj) {
      this.obj = obj
    }
  }
}
</script>

<style scoped lang="stylus">
.body-box
  background #f9f9f9
  border-radius 10px
.wrapper
  border-radius 10px
  max-width 828px
  margin 0 auto
  background #fff
  padding 35px 41px 23px 32px
  box-sizing border-box
  .Box
    display flex
    .Box-left
      width 207px
      height auto
      min-height: 1122px
      background #EAEAEA
      margin-right 28px
      .photograph
        width 117px
        height 150px
        background #F5FFFD
        border 1px solid #12AC98
        margin 24px 45px 16px 45px
        font-size 13px
        font-family PingFang SC
        font-weight 400
        color #A3A6B5
        line-height 150px
        text-align center
      .name
        text-align center
        font-size 28px
        font-family PingFang SC
        font-weight bold
        color #333333
        margin-bottom 12px
      .position-box
        margin-left 21px
        .expectation
          font-size 16px
          font-family PingFang SC
          font-weight bold
          color #12AC98
          margin-bottom 16px
        .years
          display flex
          align-items center
          margin-bottom 15px
          font-size 13px
          font-family PingFang SC
          font-weight 500
          color #333333
          .yuan
            width 5px
            height 5px
            border-radius 50%
            background #12AC98
            margin-right 5px
      .information-box
        margin-top 27px
        margin-bottom 27px
        .information-img
          width 214px
          img
            width 100%
            height 35px
        .box-list
          display flex
          flex-direction column
          font-size 13px
          font-family PingFang SC
          font-weight 500
          color #333333
          margin-left 21px
          line-height 26px
      .skill-box
        .skill-img
          width 214px
          img
            width 100%
            height 35px
        .skill-list
          margin 0 18px 23px 21px
          .skill-title
            display flex
            margin-bottom 7px
            justify-content space-between
            .skill-text
              font-size 15px
              font-family PingFang SC
              font-weight bold
              color #12AC98
            .level
              font-size 13px
              font-family PingFang SC
              font-weight bold
              color #333333
          .skill-time
            font-size 13px
            font-family PingFang SC
            font-weight 500
            color #333333
            margin-bottom 10px
          .describe
            font-size 13px
            font-family PingFang SC
            font-weight bold
            color #333333
          .describe-text
            margin-top 10px
            font-size 13px
            font-family PingFang SC
            font-weight 500
            color #333333
            line-height 22px
      .honor-box
        .honor-img
          width 214px
          img
            width 100%
            height 35px
        .honor-list
          margin-left 21px
          margin-bottom 24px
          .certificate
            font-size 15px
            font-family PingFang SC
            font-weight bold
            color #12AC98
          .honor-time
            margin-top 9px
            font-size 13px
            font-family PingFang SC
            font-weight 500
            color #333333
    .Box-rigth
      flex 1
      .experience-box
        margin-top 32px
        .experience-title
          display flex
          align-items center
          .title-img
            width 28px
            height 28px
            margin-right 7px
          .title-text
            font-size 19px
            font-family PingFang SC
            font-weight bold
            color #333333
          .title-solid
            margin-left 10px
            flex 1
            border-bottom 2px solid #12AC98
        .company
          font-size 15px
          font-family PingFang SC
          font-weight bold
          color #12AC98
          margin-bottom 14px
        .letter
          display flex
          justify-content space-between
          font-size 15px
          font-family PingFang SC
          font-weight bold
          color #333333
        .letter.company
          .school
            font-size 15px
            font-family PingFang SC
            font-weight bold
            color #12AC98
      .detailed-box
        margin-top 22px
      .describe-box
        margin-top 10px
        .describe-list
          font-size 13px
          font-family PingFang SC
          font-weight 500
          color #333333
          line-height 27px
          margin-left 10px
          .circle
            margin-top 12px
            margin-right 5px
            width 5px
            height 5px
            border-radius 50%
            background #12AC98
          .describe-text
            position relative
          .describe-text::before
            content ''
            position absolute
            top 12px
            left -8px
            width 5px
            height 5px
            background #12AC98
            border-radius 50%
        .describe-text.project
          font-size 13px
          font-family PingFang SC
          font-weight 500
          color #333333
          line-height 22px
</style>
